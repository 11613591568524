import { node, string } from 'prop-types';
import { useState } from 'react';
import * as styles from './index.module.scss';
import { openChatLP } from '../../../helpers/misc';

function ChatButton({
  children, text, className, loadingComponent,
}) {
  const [state, setState] = useState('DONE');
  return (
    <button
      type="button"
      className={className}
      onClick={() => openChatLP(() => setState('DONE'), () => setState('ERROR'), () => setState('FETCHING'))}
    >
      {state === 'FETCHING' && loadingComponent}
      {state === 'ERROR' && (
      <div className={styles.error}>
        There was a problem opening the chat client, please try again later.
      </div>
      )}
      {state === 'DONE' && (text || children)}
    </button>
  );
}

ChatButton.propTypes = {
  className: string,
  text: string,
  children: node,
  loadingComponent: node,
};

ChatButton.defaultProps = {
  className: '',
  text: '',
  children: null,
  loadingComponent: null,
};

export { ChatButton };
