import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { Location } from '../components/location';

function LocationTemplate({ data }) {
  return (
    <Location data={data?.wpPage} />
  );
}

export const query = graphql`
  query wpLocationTemplate($uri: String) {
    wpPage: wpLocation(uri: { eq: $uri }) {
      ...SeoLocation
      title
      uri
      locationSingleConfiguration {
        eaLocationCity
        eaLocationFormDisclaimer
        eaLocationGoogleMapsEmbedCode
        eaLocationHours
        eaLocationPhone
        eaLocationScheduleOnlinePage {
          title
          url
        }
        eaLocationState
        eaLocationStreetAddress01
        eaLocationStreetAddress02
        eaLocationTextNumber
        eaLocationZip
      }
    }
  }
`;

LocationTemplate.propTypes = {
  data: object,
};

LocationTemplate.defaultProps = {
  data: {},
};
export default LocationTemplate;
export { Head } from '../components/seo/seo';
