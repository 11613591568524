import PropTypes from 'prop-types';
import { useRef } from 'react';
import * as styles from './zoom-calendar.module.scss';
import cross from '../../assets/icons/grey-cross.svg';
import useClickOutside from '../../hooks/useClickOutside';

function ZoomCalendar({ onClose }) {
  const ref = useRef(null);
  useClickOutside(ref, () => onClose());
  return (
    <div ref={ref} className={styles.zoomCalendarContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.cross}>
          <button type="button" onClick={onClose} className={styles.exitButton}>
            <img className={styles.icon} src={cross} alt="close message icon" />
          </button>
        </div>
        <iframe
          title="Schedule now"
          src="https://scheduler.zoom.us/d/qeflozp0/case-assessment-with-a-coordinator-not-attorney?embedStyle=%7B%22buttonColor%22%3A%22%234f758b%22%7D&embed=true "
        />
      </div>
    </div>
  );
}

ZoomCalendar.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ZoomCalendar };
