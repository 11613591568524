import { Script } from 'gatsby';

function ChatScript() {
  return (
    <Script>
      {
      typeof window !== 'undefined' ? (
        window.lpTag = window.lpTag || {},
        typeof window.lpTag._tagCount === 'undefined' ? (window.lpTag = {
          wl: lpTag.wl || null,
          scp: lpTag.scp || null,
          site: '62461772' || '',
          section: lpTag.section || '',
          tagletSection: lpTag.tagletSection || null,
          autoStart: lpTag.autoStart !== !1,
          ovr: lpTag.ovr || {},
          _v: '1.10.0',
          _tagCount: 1,
          protocol: 'https:',
          events: {
            bind(t, e, i) {
              lpTag.defer(() => {
                lpTag.events.bind(t, e, i);
              }, 0);
            },
            trigger(t, e, i) {
              lpTag.defer(() => {
                lpTag.events.trigger(t, e, i);
              }, 1);
            },
          },
          defer(t, e) {
            e === 0 ? (this._defB = this._defB || [], this._defB.push(t)) : e === 1
              ? (this._defT = this._defT || [], this._defT.push(t)) : (this._defL = this._defL
                  || [], this._defL.push(t));
          },
          load(t, e, i) {
            const n = this;
            setTimeout(() => {
              n._load(t, e, i);
            }, 0);
          },
          _load(t, e, i) {
            let n = t;
            t || (n = `${this.protocol}//${this.ovr && this.ovr.domain ? this.ovr.domain
              : 'lptag.liveperson.net'}/tag/tag.js?site=${this.site}`);
            const o = document.createElement('script');
            o.setAttribute('charset', e || 'UTF-8'), i && o.setAttribute('id', i), o.setAttribute('src', n), document.getElementsByTagName('head').item(0).appendChild(o);
          },
          init() {
            this._timing = this._timing || {}, this._timing.start = (new Date()).getTime();
            const t = this;
            window.attachEvent ? window.attachEvent('onload', () => {
              t._domReady('domReady');
            }) : (window.addEventListener('DOMContentLoaded', () => {
              t._domReady('contReady');
            }, !1), window.addEventListener('load', () => {
              t._domReady('domReady');
            }, !1)), typeof window._lptStop === 'undefined' && this.load();
          },
          start() {
            this.autoStart = !0;
          },
          _domReady(t) {
            this.isDom || (this.isDom = !0, this.events.trigger(
              'LPT',
              'DOM_READY',
              { t },
            )), this._timing[t] = (new Date()).getTime();
          },
          vars: lpTag.vars || [],
          dbs: lpTag.dbs || [],
          ctn: lpTag.ctn || [],
          sdes: lpTag.sdes || [],
          hooks: lpTag.hooks || [],
          identities: lpTag.identities || [],
          ev: lpTag.ev || [],
        }, lpTag.init()) : window.lpTag._tagCount += 1
      ) : null
    }
    </Script>
  );
}

export { ChatScript };
