// extracted by mini-css-extract-plugin
export var entry = "location-module--entry--f8039";
export var grid = "location-module--grid--ea82c";
export var icon = "location-module--icon--1edbe";
export var inOffice = "location-module--inOffice--34174";
export var info = "location-module--info--a70e9";
export var infoContainer = "location-module--info-container--9bac9";
export var main = "location-module--main--596be";
export var map = "location-module--map--90063";
export var mapContainer = "location-module--map-container--d408c";
export var pageContainer = "location-module--page-container--7c70b";
export var popup = "location-module--popup--89c3b";
export var sendEmailButton = "location-module--send-email-button--017c6";
export var title = "location-module--title--06483";
export var titleContainer = "location-module--title-container--c2824";