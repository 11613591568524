import { object } from 'prop-types';
import { PopupButton } from '@typeform/embed-react';
import { useState } from 'react';
import { constants } from '../../config';
import { ChatButton } from '../common/chat-button';
import * as styles from './location.module.scss';
import { Wrap } from '../sections-wrap';
import location from '../../assets/icons/location/location.svg';
import clock from '../../assets/icons/location/clock.svg';
import sms from '../../assets/icons/location/sms.svg';
import call from '../../assets/icons/location/call.svg';
import schedule from '../../assets/icons/location/schedule.svg';
import email from '../../assets/icons/location/email.svg';
import chat from '../../assets/icons/location/chat.svg';
import { ChatScript } from '../chat-script';
import { openChatLP } from '../../helpers/misc';
import { ZoomCalendar } from '../zoom-calendar';

const { sizeTypeFormPopUp } = constants;

const loading = <div>Loading chat...</div>;
// This is a short term solution.
const FORT_WORTH_OFFICE = 'Fort Worth Office';

function Location({ data }) {
  const [open, setOpen] = useState(false);
  const locationData = data?.locationSingleConfiguration;
  return (
    <div className={styles.pageContainer}>
      <ChatScript />
      {open && (
        <ZoomCalendar onClose={() => setOpen(false)} />
      )}
      <Wrap>
        <main className={styles.main}>
          <article className={styles.entry}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{data.title}</h1>
            </div>
            <div>
              <div className={styles.infoContainer}>
                <div className={styles.grid}>
                  <div className={styles.info}>
                    <img className={styles.icon} src={location} alt="location" />
                    {locationData.eaLocationStreetAddress01}
                    <br />
                    {locationData.eaLocationStreetAddress02}
                    <br />
                    {locationData.eaLocationCity}
                    {', '}
                    {locationData.eaLocationState}
                    {' '}
                    {locationData.eaLocationZip}
                  </div>
                  <div className={styles.info}>
                    <img className={styles.icon} src={clock} alt="clock icon" />
                    <div dangerouslySetInnerHTML={{ __html: locationData.eaLocationHours }} />
                    {data.title !== FORT_WORTH_OFFICE && (
                      <div className={styles.inOffice}>
                        *In-office services available 8:30am to 5pm Monday - Friday
                      </div>
                    )}
                  </div>
                  {' '}
                  <div className={styles.info}>
                    <img className={styles.icon} src={call} alt="call" />
                    Call:
                    {' '}
                    <a href={`tel:${locationData?.eaLocationPhone}`}>{locationData?.eaLocationPhone}</a>
                  </div>
                  {' '}
                  <div className={styles.info}>
                    <img className={styles.icon} src={sms} alt="send sms" />
                    Text:
                    {' '}
                    <button type="button" onClick={() => openChatLP(() => null)}>
                      {locationData?.eaLocationTextNumber}
                    </button>
                  </div>
                  {' '}
                  <div className={styles.info}>
                    <img className={styles.icon} src={chat} alt="start chat" />
                    <ChatButton text="Start a Chat" openLocalChat loadingComponent={loading} />
                  </div>
                  {' '}
                  <div className={styles.info}>
                    <button onClick={() => setOpen(true)} type="button">
                      <img className={styles.icon} src={schedule} alt="schedule" />
                      <div>
                        {locationData?.eaLocationScheduleOnlinePage?.title || 'Schedule Online'}
                        {' '}
                        24/7
                      </div>
                    </button>
                  </div>
                  <div className={styles.info}>
                    <img className={styles.icon} src={email} alt="email" />
                    <PopupButton
                      id={process.env.GATSBY_CONSULTATION_FORM_ID}
                      type="button"
                      className={styles.sendEmailButton}
                      size={sizeTypeFormPopUp}
                    >
                      Send us an email
                    </PopupButton>
                  </div>
                </div>

              </div>
              {' '}
              <div className={styles.mapContainer}>
                <p
                  className={styles.map}
                  dangerouslySetInnerHTML={{ __html: locationData.eaLocationGoogleMapsEmbedCode }}
                />
              </div>
            </div>
          </article>
        </main>
      </Wrap>
    </div>
  );
}

Location.propTypes = {
  data: object,
};

Location.defaultProps = {
  data: {},
};

export { Location };
